<template>
  <el-dialog
    title="可选专业列表"
    :visible.sync="visible"
    width="80%"
    :before-close="handleClose">
    <el-table 
      :data="tableData" 
      ref="majorTable"
      height="300" 
      stripe
      border
      :header-cell-style="{background:'#e6e6e6', color: '#646464', textAlign: 'center'}"
      :cell-style="{ textAlign: 'center' }"
      v-loading="loading"
      @selection-change="handleSelectionChange">
      <el-table-column type="selection" min-width="50" align="center"></el-table-column>
      <el-table-column prop="school" label="院校" min-width="180" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="major" label="专业" min-width="180" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="majorRemark" label="专业备注" min-width="120" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="tuition" label="学费/学制" min-width="100" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="planCompare" label="招生计划比" min-width="120" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="lastScore" label="去年录取分" min-width="120" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="referScore" label="参考分" min-width="100" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="subjectRank" label="学科评估" min-width="100" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="postgraduateRate" label="保研率" min-width="100" align="center" :show-overflow-tooltip="true"></el-table-column>
    </el-table>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleCancel">取 消</el-button>
      <el-button type="primary" @click="handleConfirm">保 存</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  name: 'MajorDialog',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    schoolData: {
      type: Object,
      default: () => ({})
    },
    studentId: {
      type: [String, Number],
      default: 0
    },
    isChecked: {
      type: [String, Boolean],
      default: null
    }
  },
  data() {
    return {
      loading: false,
      tableData: [],
      selectedRows: []
    }
  },
  watch: {
    visible(newVal) {
      if (newVal) {
        this.loadTableData()
      } else {
        this.tableData = []
      }
    }
  },
  methods: {
    handleClose(done) {
      this.tableData = []
      this.$emit('update:visible', false)
      done()
    },
    loadTableData() {
      this.loading = true;
      this.$http.get(this.$apis.getApplyRecruitingSchemeGroup, {groupCode: this.schoolData.group_code, studentId: this.studentId, isChecked: this.isChecked ? this.isChecked : null}).then(res => {
        if (res.code === 200) {
          this.tableData = res.data.map(item => ({
            id: item.id,
            school: item.schoolName,
            major: item.majorName,
            isChecked: false,
            majorRemark: item.note,
            tuition: `${item.schoolYear}年/${item.tuition}`,
            planCompare: item.planRate,
            lastScore: item.preYearScore,
            referScore: item.estimatedScore,
            subjectRank: item.subjectRank,
            postgraduateRate: item.postgraduateRate
          }));
          this.$nextTick(() => {
            this.tableData.forEach(row => {
              console.log(this.schoolData.majorList);
              if (this.schoolData.majorList.find(item => item.id === row.id)) {
                row.isChecked = true
                this.$refs.majorTable.toggleRowSelection(row, true);
              }
            });
          })
        } else {
          this.$message.error(res.data.message || '获取专业信息失败');
        }
      }).catch(err => {
        this.$message.error('获取专业信息失败');
        console.error('获取专业信息失败:', err);
      }).finally(() => {
        this.loading = false;
      });
    },
    handleSelectionChange(selection) {     
      this.selectedRows = selection
      this.tableData.forEach(item => {
        if (this.selectedRows.find(row => row.id === item.id)) {
          item.isChecked = true
        } else {
          item.isChecked = false
        }
      })
      this.$emit('selection-change', selection)
    },
    handleCancel() {
      this.$emit('update:visible', false)
    },
    handleConfirm() {
      if (this.selectedRows.length > 6) {
        this.$message.error('最多只能选择6个专业')
        return
      }
      console.log(this.tableData);
      this.$emit('confirm', this.tableData)
      this.$emit('update:visible', false)
    }
  }
}
</script> 